import React from 'react';
import TogetherBanner from 'components/togetherBanner';
import SEO from 'components/seo';


const Impact = () => (
  <>
    <SEO title="How We Impact" />
    <div>
      <div className="container-fluid pt-6 my-5">
        <div className="row">
          <div className="col-12 col-lg-6 pr-md-6 order-1 order-lg-1">
            <h1 className="font-weight-bolder underscore mb-3">
              Social<br />Impact
            </h1>
            <div className="display-6 font-weight-light pr-6 pb-5">
              <p>
              Technological innovation affects all aspects of our world, from agriculture and healthcare to shipping and eco-friendly transportation and to how we communicate with each other.
              </p>
              <p className="font-weight-bold">
              We work with dynamic technologies, new sensations, new ways of doing business.
              </p>
              <p>
              Our expertise spreads across various industries, from medicine, transportation, sound & acoustics, to art, finance, e-commerce and beyond.
              </p>
              <p>
              We understand innovation and its social impact on enterprises and we ensure that technology innovation furthers societal growth.
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6 order-2 order-lg-2 pt-7">
            <img className="img-fluid" src="/assets/impact/social-impact.jpg" alt=" " />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-stretch">
        <div className="container-fluid my-5">
          <div className="row">
            <div className="col-12 col-lg-6 order-2 order-lg-1 pt-7">
              <img className="img-fluid" src="/assets/impact/impact-investment.jpg" alt=" " />
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2">
              <h1 className="font-weight-bolder underscore">
              Impact<br />Investment
              </h1>
              <div className="display-6 font-weight-light">
                <p>
                At Emvelia, we develop innovative technology for the greater good.
                </p>
                <p>
                We are at the crossroads of technology, innovation and sustainability, where artificial intelligence has the ability to make a dramatic impact on ESG investing.  We find examples in business where both our clients and us are exposed to a myriad of risks from relatively infrequent but high impact ESG-related events. Our set of standards for innovative technology products and services fulfil socially conscious investors to screen potential investments.
                </p>
                <p>
                We capture value by quantifying and measuring the true impact not only of a policy but also of the final product and service by measuring the sustainability and societal impact of ground-breaking innovation for businesses and their customers.
                </p>
              </div>
            </div>
          </div>

        </div>
        <div>
          <img className="d-none d-xl-block" src="/assets/impact/graph-right.png" style={{ width: 104 }} alt=" " />
        </div>
      </div>
      <div className="d-flex">
        <div>
          <img className="d-none d-xl-block" src="/assets/impact/graph-left.png" style={{ width: 104 }} alt=" " />
        </div>

        <div className="container-fluid my-5">
          <div className="row">
            <div className="col-12 col-lg-6 pr-lg-6">
              <h1 className="font-weight-bolder underscore">
              Art<br /> & Culture
              </h1>
              <div className="display-6 font-weight-light pr-6">
                <p>
                Through <a href="https://artlimes.com" target="_blank">artlimes.com</a> we democratise art, we envision a world where people have easy access to affordable masterpieces from all around the world. We have created a marketplace that nourishes creative expression and at the same time our advanced technology provides increased accessibility to all. We strongly believe that the notion of art as elitist, is decades out of date.
                </p>
                <p>
                We create radical changes in the creative industry by offering easy buying experiences for collectors and the non-connaisseurs.
                </p>
                <p>
                Our purpose is to enrich all people's lives through art and make them feel unique and special. Which is why we tend to stir away from mass production and instead aim to offer distinct and original pieces that tell a story. All our Editions of original masterpieces come with a Certificate of Authenticity that assures uniqueness and enhances the value for money factor.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-6 d-flex align-items-center pt-7">
              <img className="img-fluid" src="/assets/impact/art-culture.jpg" className="img-fluid" alt=" " />
            </div>
          </div>
        </div>
      </div>
      <TogetherBanner />
    </div>
  </>
);

export default Impact;
